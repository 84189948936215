import React from 'react'
// import _ from 'lodash';
import { graphql } from 'gatsby'

import { Layout } from '../components/index'

// this minimal GraphQL query ensures that when 'gatsby develop' is running,
// any changes to content files are reflected in browser
export const query = graphql`
  query ($url: String) {
    sitePage(path: { eq: $url }) {
      id
    }
  }
`

export default class Data extends React.Component {
  render() {
    let articles = this.props.pageContext.html
      .split('<h1>')
      .slice(1)
      .map((item) => {
        let parts = item.split('</h1>')
        let data = parts[1]
          .split('<p>')
          .splice(1)
          .map((item) => item.replace('</p>', '').trim())
        let result = {
          title: parts[0],
          paragraph: data[0],
          imageSource: data[1].split('"')[1]
        }

        return result
      })

    return (
      <Layout {...this.props}>
        <article className='post page post-full'>
          <header className='post-header inner-sm'>
            <h1 className='post-title underline'>
              {this.props.pageContext.frontmatter.title}
            </h1>
            <div className='post-subtitle inner-sm'>
              {this.props.pageContext.frontmatter.subtitle}
            </div>
          </header>
          <div className='post-feed'>
            <div className='post-feed-inside'>
              {articles.map((item, index) => {
                return (
                  <article key={index} className='post post-card'>
                    <div className='post-inside'>
                      {item.imageSource === null ? null : (
                        <div className='post-column'>
                          <a className='post-thumbnail' href=''>
                            <img src={item.imageSource} alt='data' />
                          </a>
                        </div>
                      )}
                      <div className='post-wide-column'>
                        <header className='post-header'>
                          <h2 className='post-title'>{item.title}</h2>
                        </header>
                        <div className='post-content'>
                          <p
                            dangerouslySetInnerHTML={{ __html: item.paragraph }}
                          />
                        </div>
                      </div>
                    </div>
                  </article>
                )
              })}
            </div>
          </div>
        </article>
      </Layout>
    )
  }
}
